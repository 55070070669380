import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Heading, Paragraph } from 'grommet';
import loadable from '@loadable/component';

const FormiumForm = loadable(() => import('../Forms/FormiumForm'));
import useRichText from '../useRichText';

import { useFormiumSchema } from '../Forms/useFormiumSchema';

const DynamicFormiumForm = ({
  blok: { projectId, slug, showTitle, successTitle, successBody, captcha },
}) => {
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({});
  const [schema, error] = useFormiumSchema(projectId, slug);
  const richText = useRichText(successBody, values);

  return (
    <Box pad="medium">
      {error ? (
        <Text>{error}</Text>
      ) : success ? (
        <Box pad="large">
          <Heading level={2}>{successTitle || 'Success'}</Heading>
          {successBody ? (
            richText
          ) : (
            <Paragraph>Thank you for your submission</Paragraph>
          )}
        </Box>
      ) : (
        <>
          {schema && (
            <FormiumForm
              data={schema}
              showTitle={showTitle}
              captcha={captcha}
              onComplete={(result, values) => {
                setSuccess(true);
                setValues(values);
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

DynamicFormiumForm.propTypes = {
  blok: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    successTitle: PropTypes.string,
    successBody: PropTypes.object,
    captcha: PropTypes.bool,
  }),
};

export default DynamicFormiumForm;
