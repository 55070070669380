import { useEffect, useState } from 'react';

export const useFormiumSchema = (projectId, slug) => {
  const [schema, setSchema] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // Fetch form from Backdrop API
      try {
        const response = await fetch(
          `${process.env.GATSBY_BACKDROP_API_URI}/formium/${projectId}/${slug}`,
          {
            method: 'GET',
            headers: {
              'x-backdrop-api-key': process.env.GATSBY_BACKDROP_FORMIUM_API_KEY,
            },
          }
        );
        const formData = await response.json();
        if (formData) {
          setSchema(formData);
        }
      } catch (e) {
        setError('Something went wrong when trying to display this form.');
      }
    }
    fetchData();
  }, []);

  return [schema, error];
};

export default useFormiumSchema;
